// @ts-strict-ignore
import _ from 'lodash';

import { formatAsQueryString } from '@/utilities/httpHelpers.utilities';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { toNumber } from '@/utilities/numberHelper.utilities';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import {
  CONTENT_STATE,
  DateRange,
  DEFAULT_HEIGHT,
  DEFAULT_SHAPE_KEY,
  DEFAULT_SIZE_KEY,
  DEFAULT_WIDTH,
  InProgressContentProperties,
  REPORT_CONTENT,
  ReportContentSummary,
  SCREENSHOT_SIZE_TO_CONTENT,
} from '@/reportEditor/report.constants';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { parseSummaryToTypeAndValue } from '@/annotation/reportContent.utilities';

export class ReportContentStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqReportContentStore';

  private getShapeFromKey = (key: string) => _.find(REPORT_CONTENT.SHAPE, { key });
  private getSizeFromKey = (key: string) => _.find(REPORT_CONTENT.SIZE, { key });

  initialize(initializeMode?: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable({
      id: undefined,
      modalName: '',
      evalParamsModified: 0,
      // for new content, these params will default to values from the last loaded content
      dateRangeId: saveState ? this.state.get('dateRangeId') : undefined, // undefined === inherit date range
      assetSelectionId: saveState ? this.state.get('assetSelectionId') : undefined, // undefined === inherit selection
      width: saveState ? this.state.get('width') : DEFAULT_WIDTH,
      height: saveState ? this.state.get('height') : DEFAULT_HEIGHT,
      sizeKey: saveState ? this.state.get('sizeKey') : DEFAULT_SIZE_KEY.key,
      shapeKey: saveState ? this.state.get('shapeKey') : DEFAULT_SHAPE_KEY.key,
      scale: saveState ? this.state.get('scale') : REPORT_CONTENT.SCALE.NORMAL.key,
      useSizeFromRender: false,
      canUseReact: false,
      isReact: false,
      summary: saveState ? this.state.get('summary') : undefined, // undefined === inherit summary
      previewUrl: this.monkey(
        ['id'],
        ['worksheetId'],
        ['workstepId'],
        ['height'],
        ['width'],
        ['scale'],
        ['useSizeFromRender'],
        ['dateRangeId'],
        ['assetSelectionId'],
        ['summary'],
        (
          contentId,
          worksheetId,
          workstepId,
          height,
          width,
          scale,
          useSizeFromRender,
          dateRangeId,
          assetSelectionId,
          summary,
        ) => {
          const potentialAsset = _.find(sqReportStore.assetSelectionsNotArchived, ['id', assetSelectionId])?.asset.id;
          const params = {
            worksheetId,
            workstepId,
            height,
            width,
            scale,
            dateRangeId,
            assetId: potentialAsset,
            timezone: sqWorksheetStore.timezone?.name,
            selector: useSizeFromRender ? SCREENSHOT_SIZE_TO_CONTENT.SELECTOR : undefined,
            reportId: sqReportStore.id,
            ...parseSummaryToTypeAndValue(summary),
          };

          const queryString = formatAsQueryString(params);

          return `${APPSERVER_API_PREFIX}/content/preview?${queryString}`;
        },
      ),
      paramsModified: this.monkey(
        ['id'],
        ['workstepId'],
        ['height'],
        ['width'],
        ['scale'],
        ['useSizeFromRender'],
        ['dateRangeId'],
        ['assetSelectionId'],
        ['summary'],
        ['isReact'],
        ['evalParamsModified'],
        (id, workstepId, height, width, scale, useSizeFromRender, dateRangeId, assetSelectionId, summary, isReact) => {
          if (!id) return true;
          const savedContent = sqReportStore.getContentById(id);
          return (
            savedContent.workstepId !== workstepId ||
            (!useSizeFromRender && (savedContent.height !== height || savedContent.width !== width)) ||
            savedContent.scale !== scale ||
            savedContent.useSizeFromRender !== useSizeFromRender ||
            savedContent.dateRangeId !== dateRangeId ||
            !_.isEqual(savedContent.summary, summary) ||
            savedContent.assetSelectionId !== assetSelectionId ||
            savedContent.isReact !== isReact
          );
        },
      ),
    });
  }

  get id() {
    return this.state.get('id');
  }

  get name() {
    return `content_${this.state.get('worksheetId')}_${this.state.get('workstepId')}`;
  }

  get isNew() {
    return !this.state.get('id');
  }

  get workbookId() {
    return this.state.get('workbookId');
  }

  get worksheetId() {
    return this.state.get('worksheetId');
  }

  get workstepId() {
    return this.state.get('workstepId');
  }

  get workbookName() {
    return this.state.get('workbookName');
  }

  get worksheetName() {
    return this.state.get('worksheetName');
  }

  get scale() {
    return this.state.get('scale');
  }

  get width() {
    return this.state.get('width');
  }

  get height() {
    return this.state.get('height');
  }

  get summary() {
    return this.state.get('summary');
  }

  get sizeKey() {
    return this.state.get('sizeKey');
  }

  get sizeConstant() {
    return this.getSizeFromKey(this.state.get('sizeKey'));
  }

  get shapeKey() {
    return this.state.get('shapeKey');
  }

  get shapeConstant() {
    return this.getShapeFromKey(this.state.get('shapeKey'));
  }

  get dateRangeId() {
    return this.state.get('dateRangeId');
  }

  get assetSelectionId() {
    return this.state.get('assetSelectionId');
  }

  get useSizeFromRender() {
    return this.state.get('useSizeFromRender');
  }

  get canUseReact() {
    return this.state.get('canUseReact');
  }

  get isReact() {
    return this.state.get('isReact');
  }

  get dateRange() {
    return _.find(sqReportStore.dateRanges, ['id', this.state.get('dateRangeId')]);
  }

  get paramsModified() {
    return this.state.get('paramsModified');
  }

  get autoUpdate() {
    return _.get(_.find(sqReportStore.dateRanges, ['id', this.state.get('dateRangeId')]), 'auto.enabled');
  }

  get previewUrl() {
    return this.state.get('previewUrl');
  }

  get sourceUrl() {
    return this.state.get('sourceUrl');
  }

  get modalName() {
    return this.state.get('modalName');
  }

  getProperties(): InProgressContentProperties {
    return {
      id: this.id,
      sourceUrl: this.sourceUrl,
      autoUpdate: this.autoUpdate,
      modalName: this.modalName,
      paramsModified: this.paramsModified,
      isReact: this.isReact,
      canUseReact: this.canUseReact,
      useSizeFromRender: this.useSizeFromRender,
      assetSelectionId: this.assetSelectionId,
      dateRangeId: this.dateRangeId,
      shapeConstant: this.shapeConstant,
      shapeKey: this.shapeKey,
      sizeConstant: this.sizeConstant,
      sizeKey: this.sizeKey,
      summary: this.summary,
      scale: this.scale,
      height: this.height,
      width: this.width,
      worksheetName: this.worksheetName,
      worksheetId: this.worksheetId,
      workbookName: this.workbookName,
      workbookId: this.workbookId,
      workstepId: this.workstepId,
      isNew: this.isNew,
      name: this.name,
    };
  }

  dehydrate() {
    return {};
  }

  rehydrate() {
    // CRAB-23743: No-ops to prevent errant state persisting between worksheets when switching, creating new, or
    // duplicating a given document. If we don't do this, then content can error when attempting to insert as we can
    // reference date ranges and asset selections that belong to another document, which will fail in a confusing
    // way.
  }

  protected readonly handlers = {
    REPORT_CONTENT_CLEAR: this.clear,
    REPORT_CONTENT_CLEAR_FORCE: this.forceClear,
    REPORT_CONTENT_SET_CONTENT_ID: this.setContentId,
    REPORT_CONTENT_SET_WORKBOOK_ID: this.setWorkbookId,
    REPORT_CONTENT_SET_WORKSHEET_ID: this.setWorksheetId,
    REPORT_CONTENT_SET_WORKSTEP_ID: this.setWorkstepId,
    REPORT_CONTENT_SET_WIDTH: this.setWidth,
    REPORT_CONTENT_SET_HEIGHT: this.setHeight,
    REPORT_CONTENT_SET_SIZE_KEY: this.setSizeKey,
    REPORT_CONTENT_SET_SHAPE_KEY: this.setShapeKey,
    REPORT_CONTENT_SET_SCALE: this.setScale,
    REPORT_CONTENT_SET_DATE_RANGE_ID: this.setDateRangeId,
    REPORT_SET_CONTENT: this.evalParamsModified,
    REPORT_CONTENT_SET_SOURCE_URL: this.setSourceUrl,
    REPORT_CONTENT_SET_USE_SIZE_FROM_RENDER: this.setUseSizeFromRender,
    REPORT_CONTENT_SET_CAN_USE_REACT: this.setCanUseReact,
    REPORT_CONTENT_SET_IS_REACT: this.setIsReact,
    REPORT_CONTENT_SET_SUMMARY: this.setSummary,
    REPORT_CONTENT_SET_ASSET_SELECTION_ID: this.setAssetSelectionId,
    REPORT_SET_DATE_RANGE: this.setDateRange,
    REPORT_CONTENT_SET_MODAL_NAME: this.setModalName,
  };

  /**
   * Clears the state for the currently loaded seeq content image.
   */
  private clear() {
    this.initialize();
  }

  private forceClear() {
    // We use FORCE here because otherwise the store will save the state for certain parameters from the last loaded
    // piece of content. E.g. dateRangeId, assetSelectionId, size, shape, etc
    this.initialize('FORCE');
  }

  /**
   * @param id - ID of the content. May be empty if content is new and hasn't been saved to backend yet
   */
  private setContentId(id: string) {
    this.state.set('id', id);
  }

  /**
   * @param workbookId - workbook ID of the content
   */
  private setWorkbookId(workbookId: string) {
    this.state.set('workbookId', workbookId);
  }

  /**
   * @param worksheetId - worksheet ID of the content
   */
  private setWorksheetId(worksheetId: string) {
    this.state.set('worksheetId', worksheetId);
  }

  /**
   * @param workstepId - workstep ID of the content
   */
  private setWorkstepId(workstepId: string) {
    this.state.set('workstepId', workstepId);
  }

  /**
   * @param width - width of the content
   */
  private setWidth(width: number) {
    this.state.set('width', toNumber(width));
  }

  /**
   * Sets the scale setting of the content
   *
   * @param payload - Object container
   * @param payload.scale - a REPORT_CONTENT SCALE key
   */
  private setScale(payload: { scale: string }) {
    const scale = toNumber(payload.scale);
    if (_.chain(REPORT_CONTENT.SCALE).values().map('key').includes(scale).value()) {
      this.state.set('scale', scale);
    }
  }

  /**
   * @param height - height of the content
   */
  private setHeight(height: string) {
    this.state.set('height', toNumber(height));
  }

  /**
   * @param sizeKey - Size key for the content size, from REPORT_CONTENT.SIZE objects
   */
  private setSizeKey(sizeKey: string) {
    this.state.set('sizeKey', sizeKey);
  }

  /**
   * @param shapeKey - Shape key for the content, from REPORT_CONTENT.SHAPE objects
   */
  private setShapeKey(shapeKey: string) {
    this.state.set('shapeKey', shapeKey);
  }

  /**
   * @param dateRangeId - id of the dateRange used for the content
   */
  private setDateRangeId(dateRangeId: string | undefined) {
    this.state.set('dateRangeId', dateRangeId ? dateRangeId : undefined);
  }

  /**
   * @param id - id used for asset selection used for the content
   */
  private setAssetSelectionId(id: string | number) {
    this.state.set('assetSelectionId', id ? id : undefined);
  }

  /**
   * Recalculate .paramsModified based on something happening outside this store, i.e. content
   * saved and updated in sqReportStore.
   */
  private evalParamsModified() {
    const count = this.state.get('evalParamsModified');
    this.state.set('evalParamsModified', count + 1);
  }

  /**
   * Sets the URL to the source worksheet, with any override parameters specified (e.g. date ranges)
   *
   * @param sourceUrl - URL for the source of this content, i.e. worksheet w/workstep
   */
  private setSourceUrl(sourceUrl: string) {
    this.state.set('sourceUrl', sourceUrl);
  }

  /**
   * @param useSizeFromRender - if true the content size is determined by the size of the rendered content,
   *  and not any user-specified size/shape parameters
   */
  private setUseSizeFromRender(useSizeFromRender: boolean) {
    const previousValue = this.state.get('useSizeFromRender');
    if (previousValue && !useSizeFromRender) {
      // When switching from true to false, reset size/shape to defaults
      this.state.set('height', DEFAULT_HEIGHT);
      this.state.set('width', DEFAULT_WIDTH);
      this.state.set('sizeKey', DEFAULT_SIZE_KEY.key);
      this.state.set('shapeKey', DEFAULT_SHAPE_KEY.key);
    }
    this.state.set('useSizeFromRender', !!useSizeFromRender);
  }

  private setCanUseReact(canUseReact: boolean) {
    this.state.set('canUseReact', canUseReact);
  }

  private setIsReact(isReact: boolean) {
    this.state.set('isReact', isReact);
  }

  private setDateRange(dateRange: DateRange) {
    if (dateRange.isArchived && dateRange.id === this.state.get('dateRangeId')) {
      this.state.set('dateRangeId', undefined);
    }
  }

  private setSummary(obj: { summary: ReportContentSummary }) {
    this.state.set('summary', obj.summary);
  }

  private setModalName(modalName: CONTENT_STATE | '') {
    this.state.set('modalName', modalName);
  }
}
